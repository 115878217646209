import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import EmailLogin from "./components/EmailLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/Layout";
import EventPage from "./pages/EventCalendar";
import Business from "./pages/Business";
// import Universe from "./pages/Universe";
import Inventory from "./pages/Inventory";
// import Approvals from "./pages/Approvals";
// import Commercials from "./pages/Commercials";
// import Content from "./pages/Content";
// import Purchase from "./pages/Purchase";
// import SocialMedia from "./pages/SocialMedia";
import ComingSoon from "./components/ComingSoon";
import mixpanel from 'mixpanel-browser';
// Import other page components as needed

mixpanel.init("1a0896f4624bf8b78d7724b066596449", {
  debug: true, // Optional, for debugging
});

function App() {
  return (
    <Router>
      <AuthProvider>
        <UserProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<EmailLogin />} />
            <Route path="/event-calendar" element={<EventPage />} />

            {/* Protected routes with Layout and Sidebar */}
            <Route element={ <ProtectedRoute> <Layout /> </ProtectedRoute> }>
              <Route path="/" element={<ComingSoon />} />
              <Route path="/business" element={<Business />} />
              <Route path="/approvals" element={<ComingSoon />} />
              <Route path="/commercials" element={<ComingSoon />} />
              <Route path="/content" element={<ComingSoon />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/purchase" element={<ComingSoon />} />
              <Route path="/social-media" element={<ComingSoon />} />

              <Route path="/settings" element={<div>Settings Content</div>} />
              <Route path="/help" element={<ComingSoon />} />
            </Route>
          </Routes>
        </UserProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
