import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import api from "../api";
import { trackEvent } from "../utils/trackEvent";
import { EnvelopeIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import Modal from "./ContactModal";

const EmailLogin = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [timeLeft, setTimeLeft] = useState(30);
  const [stateId, setStateId] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [showContactModal, setShowContactModal] = useState(false);

  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  // Redirect if user is already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  // Countdown timer for resending OTP
  useEffect(() => {
    let timer;
    if (otpSent && timeLeft > 0) {
      timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
    } else if (timeLeft === 0) {
      setSuccessMessage("You can now resend the OTP.");
    }
    return () => clearInterval(timer);
  }, [otpSent, timeLeft]);

  // Handle "Enter" key press for sending or verifying OTP
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !loading) {
        otpSent ? handleVerifyOtp() : handleRequestOtp();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpSent, email, otp, loading]);

  // Helper to reset messages
  const resetMessages = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  // Request OTP from the server
  const handleRequestOtp = async () => {
    if (!email) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    resetMessages();

    try {
      const response = await api.post("/api/auth/brand/login", { email });
      const { showContactModal: shouldShowModal, state_id } = response.data;

      if (shouldShowModal) {
        setShowContactModal(true);
        trackEvent(email, "Contact Modal Shown", { email });
        return;
      }

      if (!state_id) throw new Error("Failed to retrieve state ID.");

      setStateId(state_id);
      setOtpSent(true);
      setTimeLeft(30);
      setSuccessMessage("OTP has been sent to your email.");
      trackEvent(email, "OTP Requested", { email });
    } catch (error) {
      handleApiError(error, "Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Verify OTP
  const handleVerifyOtp = async () => {
    if (!otp) {
      setErrorMessage("Please enter the OTP.");
      return;
    }

    setLoading(true);
    resetMessages();

    try {
      const response = await api.post("/api/auth/brand/verify", {
        state_id: stateId,
        otp,
      });

      const { accessToken, showContactModal: shouldShowModal } = response.data;

      if (shouldShowModal) {
        setShowContactModal(true);
        trackEvent(email, "Contact Modal Shown", { email });
        return;
      }

      if (!accessToken) throw new Error("Access token not received.");

      // Store access token and authenticate user
      localStorage.setItem("accessToken", accessToken);
      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      setIsAuthenticated(true);

      trackEvent(email, "OTP Verified", { email });
      navigate("/");
    } catch (error) {
      handleApiError(error, "Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Resend OTP logic
  const handleResendOtp = () => {
    setOtpSent(false);
    setOtp("");
    setTimeLeft(30);
    resetMessages();
    trackEvent(email, "OTP Resent", { email });
    handleRequestOtp();
  };

  // Close the contact modal
  const closeModal = () => setShowContactModal(false);

  // Handle API errors consistently
  const handleApiError = (error, defaultMsg) => {
    const errMsg =
      error.response?.data?.error ||
      error.response?.data?.message ||
      defaultMsg;

    // If the server indicates showing the contact modal again
    if (error.response?.data?.showContactModal) {
      setShowContactModal(true);
      trackEvent(email, "Contact Modal Shown on Error", { email });
    } else {
      setErrorMessage(errMsg);
    }
  };

  return (
    <div className="relative min-h-screen">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center opacity-30"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/delwjbj2a/image/upload/v1732615063/Screenshot_2024-11-26_at_11.16.18_AM_budnza.png')",
        }}
      ></div>

      {/* Content Wrapper */}
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen px-4">
        <div className="w-full max-w-lg p-6 bg-white bg-opacity-80 rounded-lg shadow-2xl">
          {/* Logo */}
          <div className="flex justify-start mb-6 pb-6 border-b border-[#B4B4B4]">
            <img
              src="https://res.cloudinary.com/delwjbj2a/image/upload/v1732644005/Group_467_s9bz4r.png"
              alt="Retailwise Logo"
              className="h-12"
            />
          </div>

          {/* Title */}
          <h1 className="text-3xl font-bold text-left text-gray-800 mb-6">
            Login to Brand Dashboard
          </h1>

          {/* Status Messages */}
          {errorMessage && (
            <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
              {successMessage}
            </div>
          )}

          {/* Form Fields */}
          {!otpSent ? (
            <>
              {/* Email Input */}
              <div className="relative mb-4">
                <EnvelopeIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                <input
                  type="email"
                  placeholder="name@mybrand.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#138784]"
                  disabled={loading}
                />
              </div>

              {/* Send OTP Button */}
              <button
                onClick={handleRequestOtp}
                className={`w-full bg-[#138784] text-white py-3 rounded-lg hover:bg-teal-700 transition ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Sending OTP..." : "Send OTP"}
              </button>
            </>
          ) : (
            <>
              {/* OTP Input */}
              <div className="relative mb-4">
                <LockClosedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  disabled={loading}
                />
              </div>

              {/* Verify OTP Button */}
              <button
                onClick={handleVerifyOtp}
                className={`w-full bg-green-600 text-white py-3 rounded-lg hover:bg-green-700 transition ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Verifying..." : "Verify OTP"}
              </button>

              {/* Resend OTP Section */}
              <div className="mt-4 flex items-center justify-between">
                {timeLeft > 0 ? (
                  <p className="text-sm text-gray-600">
                    Resend OTP in{" "}
                    <span className="font-bold text-gray-800">{timeLeft}s</span>
                  </p>
                ) : (
                  <button
                    onClick={handleResendOtp}
                    className="text-sm text-teal-600 font-bold hover:underline"
                    disabled={loading}
                  >
                    Resend OTP
                  </button>
                )}
              </div>
            </>
          )}

          {showContactModal && <Modal onClose={closeModal} />}
        </div>
      </div>
    </div>
  );
};

export default EmailLogin;
