import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // console.log("AuthProvider: Attempting to refresh access token...");
        const response = await api.post("/api/auth/brand/refresh-token");
        const { accessToken } = response.data;

        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
          setIsAuthenticated(true);
          // console.log("AuthProvider: User is authenticated.");
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error(
          "AuthProvider: Failed to refresh token:",
          error.response || error
        );
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const logout = async () => {
    try {
      await api.post("/api/auth/brand/logout");
      console.log("AuthProvider: User logged out.");
    } catch (error) {
      console.error("AuthProvider: Logout failed:", error);
    }

    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    delete api.defaults.headers.common["Authorization"];
    setIsAuthenticated(false);
    navigate("/login");
  };

  const login = async (credentials) => {
    try {
      const response = await api.post("/api/auth/brand/login", credentials);
      const { accessToken, user } = response.data;
      if (accessToken && user) {
        localStorage.setItem("accessToken", accessToken);
        // localStorage.setItem("userId", user.user_id); // If needed
        api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
        setIsAuthenticated(true);
        // console.log("AuthProvider: User logged in.");
        navigate("/");
      } else {
        setIsAuthenticated(false);
        console.error(
          "AuthProvider: Login failed. No access token or user data received."
        );
      }
    } catch (error) {
      console.error("AuthProvider: Login failed:", error);
      setIsAuthenticated(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loading, setIsAuthenticated, logout, login }}
    >
      {children}
    </AuthContext.Provider>
  );
};
