import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useMixpanelTracking } from "../hooks/useMixpanelTracking";

const Navbar = ({ sections = [], onSelectSection }) => {
  const [activeSection, setActiveSection] = useState(sections[0] || "");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { brand, loading } = useContext(UserContext);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { trackEvent } = useMixpanelTracking();

  const handleSectionClick = (section) => {
    setActiveSection(section);
    if (onSelectSection) {
      onSelectSection(section);
    }
    // Track section click
    trackEvent("Navbar Section Clicked", { section });
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => {
      const newState = !prev;
      // Track dropdown toggle
      trackEvent("Navbar Dropdown Toggled", { dropdownOpen: newState });
      return newState;
    });
  };

  const handleLogout = () => {
    // Track logout
    trackEvent("Navbar Logout", { userName: brand?.name || "Unknown" });
    logout();
  };

  const handleHelpClick = () => {
    // Track navigation to help
    trackEvent("Navbar Help Clicked", { userName: brand?.name || "Unknown" });
    navigate("/help");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown")) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    if (sections.length > 0) {
      setActiveSection(sections[0]);
    } else {
      setActiveSection("");
    }
  }, [sections]);

  return (
    <div>
      {/* Main Navbar */}
      <div className="flex items-center justify-between bg-[#FDFCFC] text-black px-4 py-2 shadow-md">
        {/* Brand Section */}
        <div className="flex items-center space-x-4">
          {loading ? (
            <div className="w-16 h-16 bg-gray-200 animate-pulse rounded-xl"></div>
          ) : (
            brand?.userBrand && (
              <img
                src={brand.userBrand.logo_url}
                alt="Brand Logo"
                className="w-16 h-16 object-contain rounded-xl"
              />
            )
          )}
        </div>

        {/* User Info and Dropdown */}
        <div className="relative dropdown">
          <div
            className="flex items-center space-x-2 cursor-pointer bg-[#F5F5F5] p-2 px-4 rounded-full"
            onClick={toggleDropdown}
            tabIndex={0}
            role="button"
            aria-expanded={dropdownOpen}
          >
            {brand?.profile_img ? (
              <img
                src={brand.profile_img}
                alt="User Avatar"
                className="w-8 h-8 object-cover rounded-full"
              />
            ) : (
              <div className="w-8 h-8 text-gray-400 rounded-full bg-gray-200 flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-3.31 0-6 2.69-6 6v2h12v-2c0-3.31-2.69-6-6-6z"
                  />
                </svg>
              </div>
            )}
            <span className="font-medium">{brand?.name || "User Name"}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>

          {/* Dropdown Menu */}
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
              <ul className="py-1">
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={handleHelpClick}
                >
                  Help
                </li>
                <li
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={handleLogout}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Horizontal Section Menu */}
      {sections.length > 0 && (
        <div className="flex bg-white text-black px-4 py-2 space-x-4 shadow-sm overflow-x-auto">
          {sections.map((section) => (
            <button
              key={section}
              onClick={() => handleSectionClick(section)}
              className={`px-5 py-1 rounded-full transition font-medium text-sm ${
                activeSection === section
                  ? "bg-[#04807E] text-white"
                  : "hover:bg-gray-300 bg-[#F3F3F3] text-[#5C5C5C]"
              }`}
            >
              {section}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Navbar;
