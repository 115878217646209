import React, { useEffect, useContext } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { UserContext } from "../context/UserContext";
import { Outlet } from "react-router-dom";
import mixpanel from "mixpanel-browser"; // ensure imported

const Layout = () => {
  const { userID, userEmail, userName } = useContext(UserContext);

  useEffect(() => {
    if (userID) {
      mixpanel.identify(userID);
      mixpanel.people.set({
        $name: userName,
        $email: userEmail,
      });
    }
  }, [userID, userEmail, userName]);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar className="h-full w-64 flex-shrink-0 bg-gray-800" />

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col">
        {/* Fixed Navbar */}
        <Navbar sections={[]} />
        {/* Scrollable Outlet */}
        <div className="flex-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
