// src/pages/Inventory.js
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import api from "../api";
import Loader from "../components/Loader";

const Inventory = () => {
  const { brand, loading: userLoading } = useContext(UserContext);
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInventoryData = async () => {
      if (!brand || userLoading) return;

      setLoading(true);
      setError(null); // Reset the error state before fetching data
      try {
        const response = await api.get(
          `/api/inventory-data?brandId=${brand.brand_id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = response.data.data || [];
        if (data.length === 0) {
          setError("No inventory data available.");
        } else {
          setInventoryData(data);
        }
      } catch (err) {
        console.error("Error fetching inventory data:", err);
        setError("Failed to fetch inventory data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchInventoryData();
  }, [brand, userLoading]);

  // Calculate totals for the last two columns
  const totalStock = inventoryData.reduce(
    (total, item) =>
      total +
      (parseInt(item["Saleable Stock Case"]) ||
        parseInt(item.saleable_stock_case) ||
        0),
    0
  );
  const totalValue = inventoryData.reduce(
    (total, item) =>
      total + (parseFloat(item.Value) || parseFloat(item.amount_value) || 0),
    0
  );

  if (userLoading || loading) {
    return <Loader message="Loading inventory..." />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <p className="text-red-500 text-lg font-semibold">{error}</p>
        </div>
      </div>
    );
  }

  if (inventoryData.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center">
          <p className="text-gray-500 text-lg font-semibold">
            No inventory data to display.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white flex-1 overflow-auto h-full">
      <h1 className="text-2xl font-bold mb-6 text-[#2E2E2E]">Closing Stock</h1>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="px-3 py-3 border border-gray-300 text-left text-sm font-semibold text-[#000000] tracking-wider">
              SKU Id
            </th>
            <th className="px-3 py-3 border border-gray-300 text-left text-sm font-semibold text-[#000000] tracking-wider">
              SKU Name
            </th>
            <th className="px-3 py-3 border border-gray-300 text-left text-sm font-semibold text-[#000000] tracking-wider">
              SKU Code
            </th>
            <th className="px-3 py-3 border border-gray-300 text-left text-sm font-semibold text-[#000000] tracking-wider">
              MRP/Pcs
            </th>
            <th className="px-3 py-3 border border-gray-300 text-left text-sm font-semibold text-[#000000] tracking-wider">
              Price/Pcs
            </th>
            <th className="px-3 py-3 border border-gray-300 text-left text-sm font-semibold text-[#000000] tracking-wider">
              Purchase Price/Pcs
            </th>
            <th className="px-3 py-3 border border-gray-300 text-left text-sm font-semibold text-[#000000] tracking-wider">
              Saleable Stock (Case)
            </th>
            <th className="px-3 py-3 border border-gray-300 text-left text-sm font-semibold text-[#000000] tracking-wider">
              Amount/Value
            </th>
          </tr>
        </thead>
        <tbody>
          {inventoryData.map((item, index) => (
            <tr key={index}>
              <td className="px-3 py-3 border border-gray-300 text-sm font-semibold text-[#000000]">
                {item.sku_id}
              </td>
              <td className="px-3 py-3 border border-gray-300 text-sm text-[#5B5B5B]">
                {item["SKU Name"] || item.sku_name}
              </td>
              <td className="px-3 py-3 border border-gray-300 text-sm text-[#5B5B5B]">
                {item["SKU Code"] || item.sku_code}
              </td>
              <td className="px-3 py-3 border border-gray-300 text-sm text-[#5B5B5B]">
                {item.MRP || item.mrp_per_piece}
              </td>
              <td className="px-3 py-3 border border-gray-300 text-sm text-[#5B5B5B]">
                {item["Price Per Piece"] || item.price_per_piece}
              </td>
              <td className="px-3 py-3 border border-gray-300 text-sm text-[#5B5B5B]">
                {item["Purchase Price Per Piece"] ||
                  item.purchase_price_per_piece}
              </td>
              <td className="px-3 py-3 border border-gray-300 text-sm text-[#5B5B5B]">
                {item["Saleable Stock Case"] || item.saleable_stock_case}
              </td>
              <td className="px-3 py-3 border border-gray-300 text-sm text-[#5B5B5B]">
                {item.Value || item.amount_value}
              </td>
            </tr>
          ))}
          {/* Totals Row */}
          <tr className="bg-blue-100 font-bold">
            <td
              colSpan={6}
              className="px-3 py-3 border border-gray-300 text-left text-sm font-bold text-blue-800 tracking-wide"
            >
              Total:
            </td>
            <td className="px-3 py-3 border border-gray-300 text-sm font-bold text-blue-800 text-left">
              {totalStock}
            </td>
            <td className="px-3 py-3 border border-gray-300 text-sm font-bold text-blue-800 text-left">
              ₹
              {totalValue.toLocaleString("en-IN", { minimumFractionDigits: 2 })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Inventory;
