import React, { useEffect, useState, useContext } from "react";
import api from "../api";
import { UserContext } from "../context/UserContext";
import Loader from "../components/Loader";

const Business = () => {
  const { brand, loading: brandLoading } = useContext(UserContext);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    if (brandLoading) return;

    if (!brand || !brand.userBrand) {
      setError(true);
      setLoading(false);
      return;
    }

    const brandName = brand.userBrand.name;
    let endpoint = "/api/metabase/embed-url";
    if (brandName.toLowerCase() === "baeku") {
      endpoint = "/api/metabase/baeku-embed-url";
    }

    const fetchEmbedUrl = async () => {
      try {
        setLoading(true);
        const response = await api.get(endpoint);
        setEmbedUrl(response.data.iframeUrl);
        setError(false);
      } catch (err) {
        console.error("Error fetching embed URL:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchEmbedUrl();
  }, [brand, brandLoading, retry]);

  if (loading || brandLoading) {
    return <Loader message="Loading dashboard..." />;
  }

  if (error || !embedUrl) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="text-center">
          <p className="text-red-500 text-lg mb-4">
            Failed to load the dashboard. Please try again later.
          </p>
          <button
            onClick={() => setRetry(retry + 1)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <h1 className="text-2xl font-semibold py-4 px-6 text-[#2E2E2E] bg-gray-100 shadow-md">
        Business Summary
      </h1>
      <div className="flex-1 p-4">
        <div className="w-full h-full rounded-lg shadow-lg bg-white overflow-hidden">
          <iframe
            src={embedUrl}
            title="Metabase Dashboard"
            className="w-full h-full border-none"
            style={{ display: "block" }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Business;
