// src/pages/EventPage.js

import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { FaBars, FaTimes } from "react-icons/fa"; // Import from React Icons
import MapComponent from "../components/MapComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EventPage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventDates, setEventDates] = useState([]);
  const [filters, setFilters] = useState({
    showUpcoming: true,
    showPast: false,
  });
  const [applyFilters, setApplyFilters] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // For mobile sidebar
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false); // For mobile date picker

  const isEventDate = (date) => {
    return eventDates.some(
      (eventDate) =>
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
    );
  };

  const handleApplyFilters = () => {
    setApplyFilters((prev) => !prev);
    setIsSidebarOpen(false); // Close sidebar after applying filters on mobile
  };

  return (
    <div className="flex flex-col h-screen w-screen">
      {/* Navbar Section */}
      <nav className="bg-white shadow-md flex items-center justify-between p-4 md:p-6">
        <div className="flex items-center">
          {/* Hamburger Menu for Mobile */}
          <button
            className="md:hidden mr-4 focus:outline-none"
            onClick={() => setIsSidebarOpen(true)}
            aria-label="Open sidebar"
          >
            <FaBars className="h-6 w-6 text-gray-700" />
          </button>
          <div className="text-2xl md:text-4xl font-semibold text-black">
            Check out our <span className="text-[#029E9B]">Event Calendar</span>
          </div>
        </div>
      </nav>

      {/* Mobile Sidebar Modal */}
      <Dialog
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        className="fixed inset-0 z-50 md:hidden"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="fixed inset-y-0 left-0 w-3/4 bg-white p-4 shadow-lg overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-[#029E9B]">Filters</h2>
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="focus:outline-none"
              aria-label="Close sidebar"
            >
              <FaTimes className="h-6 w-6 text-gray-700" />
            </button>
          </div>

          {/* Filters Content */}
          <div className="space-y-6">
            {/* Schedule Filter */}
            <div className="space-y-2">
              <h4 className="text-lg font-semibold">Schedule</h4>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showUpcoming}
                  onChange={() =>
                    setFilters({
                      showUpcoming: !filters.showUpcoming,
                      showPast: filters.showPast ? false : filters.showPast,
                    })
                  }
                  className="mr-2"
                />
                Upcoming Events
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showPast}
                  onChange={() =>
                    setFilters({
                      showUpcoming: filters.showUpcoming
                        ? false
                        : filters.showUpcoming,
                      showPast: !filters.showPast,
                    })
                  }
                  className="mr-2"
                />
                Past Events
              </label>
            </div>

            {/* Date Picker */}
            <div className="space-y-2">
              <h4 className="text-lg font-semibold">Check by Dates</h4>
              <button
                onClick={() => setIsDatePickerOpen(true)}
                className="w-full bg-gray-200 py-2 rounded-md text-left px-3 focus:outline-none"
              >
                {selectedDate
                  ? selectedDate.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })
                  : "Select a date"}
              </button>
            </div>

            {/* Apply Filters Button */}
            <button
              onClick={handleApplyFilters}
              className="w-full bg-teal-500 text-white py-2 rounded-3xl hover:bg-teal-600 transition duration-300"
            >
              Apply All Filters
            </button>
          </div>
        </div>
      </Dialog>

      {/* Mobile Date Picker Modal */}
      <Dialog
        open={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30"
      >
        <Dialog.Panel className="bg-white p-4 rounded-lg shadow-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Select a Date</h2>
            <button
              onClick={() => setIsDatePickerOpen(false)}
              className="focus:outline-none"
              aria-label="Close date picker"
            >
              <FaTimes className="h-6 w-6 text-gray-700" />
            </button>
          </div>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            inline
            highlightDates={eventDates}
            dayClassName={(date) =>
              isEventDate(date) ? "highlighted-event-date" : undefined
            }
            calendarClassName="custom-calendar"
          />
        </Dialog.Panel>
      </Dialog>

      {/* Main Content */}
      <div className="flex flex-grow">
        {/* Sidebar for Desktop */}
        <aside className="hidden md:block md:w-1/4 lg:w-1/5 bg-gray-100 p-4 shadow-lg overflow-y-auto">
          <div className="border bg-white p-4 space-y-6 h-full">
            <h2 className="text-xl font-bold text-[#029E9B] border-b border-[#029E9B] pb-2">
              Filters
            </h2>

            {/* Schedule Filter */}
            <div className="space-y-2">
              <h4 className="text-lg font-semibold">Schedule</h4>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showUpcoming}
                  onChange={() =>
                    setFilters({
                      showUpcoming: !filters.showUpcoming,
                      showPast: filters.showPast ? false : filters.showPast,
                    })
                  }
                  className="mr-2"
                />
                Upcoming Events
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showPast}
                  onChange={() =>
                    setFilters({
                      showUpcoming: filters.showUpcoming
                        ? false
                        : filters.showUpcoming,
                      showPast: !filters.showPast,
                    })
                  }
                  className="mr-2"
                />
                Past Events
              </label>
            </div>

            {/* Date Picker */}
            <div className="sidebar space-y-2">
              <h4 className="text-lg font-semibold">Check by Dates</h4>
              <div className="custom-calendar-container">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  inline
                  highlightDates={eventDates}
                  dayClassName={(date) =>
                    isEventDate(date) ? "highlighted-event-date" : undefined
                  }
                  calendarClassName="custom-calendar"
                />
              </div>
            </div>

            {/* Apply Filters Button */}
            <button
              onClick={handleApplyFilters}
              className="w-full bg-teal-500 text-white py-2 rounded-3xl hover:bg-teal-600 transition duration-300 mt-auto"
            >
              Apply All Filters
            </button>
          </div>
        </aside>

        {/* Map Section */}
        <div className="flex-1 p-4">
          <MapComponent
            selectedDate={selectedDate}
            setEventDates={setEventDates}
            filters={filters}
            applyFilters={applyFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default EventPage;
