import api from '../api';

export const trackEvent = async (distinct_id, eventName, properties = {}) => {
  if (typeof distinct_id !== 'string') {
    distinct_id = String(distinct_id);
  }

  try {
    await api.post('/api/mixpanel/track', {
      distinct_id,
      event: eventName,
      properties
    });
    // console.log('Event tracked:', eventName);
  } catch (error) {
    // console.error('Error tracking event:', error);
  }
};

export const identifyUser = async (distinct_id, properties = {}) => {
  if (typeof distinct_id !== 'string') {
    distinct_id = String(distinct_id);
  }

  try {
    await api.post('/api/mixpanel/identify', {
      distinct_id,
      properties,
    });
    // console.log('User identified:', distinct_id);
  } catch (error) {
    // console.error('Error identifying user:', error);
  }
};
