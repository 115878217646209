import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { trackEvent as rawTrackEvent } from "../utils/trackEvent";

export const useMixpanelTracking = () => {
  const { userID } = useContext(UserContext);

  const trackEvent = (eventName, properties = {}) => {
    if (!userID) {
      console.warn("No userID available. Not tracking this event.");
      return;
    }

    rawTrackEvent(userID, eventName, properties);
  };

  return { trackEvent };
};
